/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react'
import { DSSocialIcon } from 'shared-definitions/types'
import Email from 'shared-svg/Email.svg'
import Facebook from 'shared-svg/Facebook.svg'
import Info from 'shared-svg/Info.svg'
import Instagram from 'shared-svg/Instagram.svg'
import PlayStore from 'shared-svg/PlayStore.svg'
import Podcast from 'shared-svg/Podcast.svg'
import RSS from 'shared-svg/RSS.svg'
import SnapChat from 'shared-svg/Snapchat.svg'
import Tiktok from 'shared-svg/Tiktok.svg'
import Twitter from 'shared-svg/Twitter.svg'
import Youtube from 'shared-svg/Youtube.svg'
import Reddit from 'shared-svg/Reddit.svg'

const componentsMap: Record<DSSocialIcon, React.VFC> = {
  reddit: Reddit,
  facebook: Facebook,
  instagram: Instagram,
  tiktok: Tiktok,
  snapchat: SnapChat,
  email: Email,
  play_store: PlayStore,
  youtube: Youtube,
  podcast: Podcast,
  rss: RSS,
  twitter: Twitter,
}

interface SocialIconProps extends React.SVGAttributes<SVGElement> {
  icon: DSSocialIcon
}

const SocialIcon: React.VFC<SocialIconProps> = ({ icon, ...props }) => {
  const Component = componentsMap[icon]
  if (Component) {
    return <Component {...props} />
  }
  return <Info {...props} />
}

export default SocialIcon
